<template>
  <!--this component allow us to set/update items note-->
  <v-dialog v-model="visible">
    <v-card class="bg">
      <!--start title icon-->
      <v-card-title class="text-center">
        {{ this.selectedItem.item && this.selectedItem.item.name }}
      </v-card-title>
      <!--end title icon-->
      <v-card-text class="pb-0 pt-2">
        <v-textarea
          v-model="note"
          :label="$t('generic.lang_orderNoteHere')"
          dense
          outlined
          prepend-inner-icon="mdi-comment"
        />
      </v-card-text>

      <v-card-actions class="pt-0">
        <v-btn @click="visible = false" text color="primary">
          {{ $t("generic.lang_cancel") }}
        </v-btn>
        <v-spacer />
        <!--confirm btn-->
        <v-btn
          :loading="loading"
          :disabled="loading"
          @click="save"
          elevation="0"
          color="primary"
        >
          {{ $t("generic.lang_save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/*import plugins*/
import { createNamespacedHelpers } from "vuex";

export default {
  name: "ItemNoteDialog",
  data: () => ({
    itemNote: "",
    loading: false
  }),
  props: {
    dialog: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...createNamespacedHelpers("Order").mapGetters(["selectedItem"]),
    note: {
      get() {
        return this.selectedItem.note;
      },
      set(val) {
        this.itemNote = val;
      }
    },
    /*using computed attr to avoid direct update of 'dialog' prop*/
    visible: {
      get() {
        return this.dialog;
      },
      set(val) {
        this.itemNote = "";
        this.$emit("input", val);
      }
    }
  },
  methods: {
    /*update item note*/
    save() {
      this.loading = true;
      const self = this;
      let timer = setTimeout(() => {
        self.$store.dispatch("Order/itemNote", self.itemNote);
        self.loading = false;
        self.visible = false;
        clearTimeout(timer);
      }, 1500);
    }
  },
  watch: {}
};
</script>

<style scoped></style>
