<template>
  <!--Start Products Category Tabs-->
  <div v-scroll="scrolled" id="tabs" class="border-top">
    <v-tabs
      ref="tabs"
      v-model="tab"
      background-color="primary"
      color="primary"
      height="45px"
      dark
      center-active
      show-arrows
      grow
      style="z-index: 5 !important;"
      :class="isFixedTop ? 'fixed-top z-index-500' : ''"
    >
      <v-tabs-slider color="white"></v-tabs-slider>
      <v-tab
        @click="scrollToItems('category_' + category.id)"
        v-for="category in categories"
        :key="category.id"
        class="white--text"
      >
        {{ category.name }}
      </v-tab>
    </v-tabs>
    <!--End Products Category Tabs-->

    <!-- loader    -->
    <v-progress-linear indeterminate color="primary" v-if="loading" />
    <!-- end loader    -->

    <!--Start Products Card-->
    <div class="mb-12 pb-5 pt-2">
      <div
        :id="'category_' + category.id"
        v-for="(category, index) in categories"
        :key="category.id"
      >
        <v-sheet
          class="primary--text rounded-lg mx-3 mt-6 mb-0 my-10 pl-0 py-0"
          elevation="0"
          color="tertiary lighten-3"
        >
          <!-- start category background-->
          <div
            v-if="category.images && category.images.length > 0"
            class="category_background rounded-tr-lg rounded-tl-lg"
            v-bind:style="{
              backgroundImage: ` url('${category.images}')`
            }"
          ></div>
          <!-- end category background-->
          <h4 class="py-5 pl-4">{{ category.name }}</h4>
        </v-sheet>
        <v-sheet
          v-if="category.items && category.items.length > 0"
          v-intersect="onIntersect"
          :id="index"
        >
          <menu-items-card
            v-for="item in category.items"
            :item="item"
            :discount="category.discount"
            :key="item.id + index"
          />
        </v-sheet>
      </div>
    </div>
    <!--End Products Card-->

    <!-- footer -->
    <v-footer style="z-index: 5" v-bind:app="!this.isCardEmpty">
      <span class="text-muted font-size-md" style="font-size: 11px !important;">
        made with ❤️️ by <span style="color: #f84611">3POS</span> Kassensystem
      </span>

      <v-spacer />

      <a
        href="/allergens"
        class="text-muted font-size-md mr-2"
        style="font-size: 11px !important;"
      >
        {{ $t("generic.lang_Allergens") }}
      </a>
      <a
        href="/privacy"
        class="text-muted font-size-md"
        style="font-size: 11px !important;"
      >
        {{ $t("generic.lang_privacy") }}
      </a>
    </v-footer>

    <!--Start Shopping Cart-->
    <shopping-cart v-if="!isCardEmpty" />
    <!--End Shopping Cart-->
  </div>
</template>

<script>
/*import components*/
import menuItemsCard from "@/components/Menu/menuItemsCard";
import shoppingCart from "@/components/Card/shoppingCart";

/*import plugins*/
import ENDPOINTS from "@/plugins/axios/endpoints";
import { createNamespacedHelpers } from "vuex";

export default {
  props: {},
  components: { menuItemsCard, shoppingCart },
  /*load data before component rendering*/
  async beforeMount() {
    /*start loading*/
    this.loading = true;
    /*get categories*/
    this.categories = await window.axios
      .get(ENDPOINTS.SELF_SERVICE.CATEGORIES.GET_ALL)
      .then(res => res.data.data)
      .finally(() => /*stop loading*/ (this.loading = false));
  },
  watch: {},
  methods: {
    onIntersect(entries, observer) {
      if (entries && observer) {
        if (entries[0].isIntersecting) {
          this.tab = Number(entries[0].target.id);
        }
      }
    },
    scrolled() {
      this.isFixedTop =
        document.getElementById("tabs").offsetTop <=
        (window.pageYOffset || document.documentElement.scrollTop);
    },
    /**
     * scroll to specific item/section
     * */
    scrollToItems(id) {
      document.getElementById(id).scrollIntoView({ behavior: "smooth" });
    },
    async loadItems(category) {
      /*if the user requesting for the same category we'll prevent the request*/
      if (category.id === this.category.id) return;
      /*start loading*/
      this.loading = true;

      /*clone category*/
      this.category = Object.assign({}, category);

      /*load items*/
      this.items = await window.axios
        .get(
          ENDPOINTS.SELF_SERVICE.ITEMS.GAT_ALL +
            "?filter[category]=" +
            category.id,
          {
            params: this.params
          }
        )
        .then(res => res.data.data)
        .finally(() => /*stop loading*/ (this.loading = false));
      this.tab = 0;
    }
  },
  data() {
    return {
      loading: false,
      isShowingCart: false,
      dialog: false,
      NProductSelect: 0,
      orders: [],
      totalPrice: 0,
      category: {},
      tab: null,
      selected: [],

      categories: [],
      items: [],
      isFixedTop: false
    };
  },
  computed: {
    ...createNamespacedHelpers("Order").mapGetters(["isCardEmpty"]),
    ...createNamespacedHelpers("Filter").mapGetters(["search", "params"])
  }
};
</script>

<style>
.category_background {
  height: 130px !important;
  background-position: center;
  background-size: cover;
}
</style>
