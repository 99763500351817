<template>
  <div>
    <!--Start NavBar-->
    <!--    <v-toolbar class="text-center" height="100px" color="primary" flat>-->
    <div
      style="margin-top: -1px !important"
      class="primary mt-0 text-center w-100"
    >
      <v-img
        :src="this.Images.logo || '@/assets/LocalBee white.svg'"
        alt="Logo"
        width="40%"
        class="mx-auto"
      />

      <v-btn
        @click="callWaiter"
        v-if="!this.hide_waiter_button"
        :disabled="calling_waiter"
        :loading="calling_waiter"
        class="mt-3"
        rounded
        color="white"
      >
        {{ this.$t("generic.lang_call_waiter") }}
      </v-btn>
    </div>

    <v-btn
      fab
      small
      color="white"
      @click.stop="drawer = !drawer"
      v-if="hasOrder && !drawer"
      id="order-basket"
      class="swing animated"
    >
      <v-icon color="primary" size="30px">mdi-cart-check</v-icon>
    </v-btn>
    <!--    </v-toolbar>-->

    <v-navigation-drawer
      v-model="drawer"
      fixed
      temporary
      color="white"
      style="top: 0%; bottom: 0%;min-width: 100%"
    >
      <v-sheet id="qr-sheet" color="align-center fixed-top white rounded-b-lg">
        <v-btn @click="drawer = false" text class="pl-0 mt-2 ml-1">
          <v-icon class="pl-0 ml-0 ">mdi-chevron-left</v-icon>
          <span class="text-muted">{{ $t("generic.lang_menu") }}</span>
        </v-btn>
      </v-sheet>
      <v-card class="px-6" elevation="0">
        <v-card-title class="pt-0">
          <div
            id="qr-container"
            v-if="hasOrder"
            class="pa-3 mx-auto mt-0  rounded-lg white elevation-2 gradient"
          >
            <qr-code
              color="black"
              class="mx-auto ma-0"
              :text="
                ((this.Order && this.Order.Order && this.Order.Order.id) ||
                  this.unpaidOrder) + ''
              "
              :size="125"
              level="H"
            />
          </div>
        </v-card-title>
        <v-card-text class="pa-0 fill-height">
          <div class="text-center  black--text">
            <h6 id="party_name">
              {{ this.party }}
            </h6>
          </div>
          <!-- list items -->
          <v-container
            v-bind:style="{
              'padding-bottom': this.hasUnpaidOrder ? '120px !important' : '0'
            }"
            fluid
            class="pa-0 rounded-lg mt-5 fill-height"
          >
            <!-- paid items -->
            <div v-for="(order, i) in orders" :key="i" class="mx-0 mt-4 w-100">
              <h5 class="order_x_items_title">
                {{ $t("generic.lang_orderNo") }} {{ i + 1 }}
              </h5>

              <v-simple-table dense class="items_simple_table mx-auto mt-2">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left table_header_titles black--text">
                        {{ $t("generic.lang_item") }}s
                      </th>
                      <th
                        class="text-right table_header_titles  font-weight-bold black--text"
                      >
                        {{ $t("generic.lang_total") }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in order.items" :key="item.id">
                      <td>
                        <v-list-item class="unpaid_item">
                          <v-list-item-avatar
                            size="60"
                            tile
                            class="rounded-lg pa-1"
                            color="bg"
                          >
                            <v-img
                              :src="item.item.image || Images.product_image"
                            />
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title class="unpaid_items_name">
                              {{ item.item.name }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              <div class="unpaid_items_price">
                                {{ item.item.finalizedPrice | currency }}
                              </div>
                              <div class="unpaid_items_quantity">
                                Menge: {{ item.quantity }}x
                              </div>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </td>
                      <td class="unpaid_item_total text-right">
                        {{ item.finalPrice | currency }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>

            <!-- unpaid items -->
            <div class="mt-7">
              <h5 v-if="hasUnpaidOrder" class="" id="unpaid_items_title">
                {{ $t("generic.lang_unpaidItems") }}
              </h5>
              <v-simple-table
                v-if="hasUnpaidOrder"
                dense
                class="items_simple_table mx-auto mt-2"
              >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left table_header_titles black--text">
                        {{ $t("generic.lang_item") }}
                      </th>
                      <th
                        class="text-right table_header_titles  font-weight-bold black--text"
                      >
                        {{ $t("generic.lang_total") }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in unpaidItems" :key="item.id">
                      <td>
                        <v-list-item class="unpaid_item">
                          <v-list-item-avatar
                            size="60"
                            tile
                            class="rounded-lg pa-1"
                            color="bg"
                          >
                            <v-img
                              :src="item.item.image || Images.product_image"
                            />
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title class="unpaid_items_name">
                              {{ item.item.name }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              <div class="unpaid_items_price">
                                {{
                                  !!item.size
                                    ? item.size.finalizedPrice
                                    : item.item.finalizedPrice | currency
                                }}
                              </div>
                              <div class="unpaid_items_quantity">
                                Menge: {{ item.quantity }}x
                              </div>
                              <v-divider
                                v-if="
                                  Array.isArray(item.extras) &&
                                    item.extras.length > 0
                                "
                              />
                              <div>
                                <ul>
                                  <li
                                    :key="ext.id + '-' + index"
                                    v-for="(ext, index) in item.extras"
                                  >
                                    <strong class="primary--text">{{
                                      ext.quantity
                                    }}</strong
                                    >x {{ ext.name }} <br />(<strong
                                      class="primary--text"
                                      >{{
                                        (ext.quantity * ext.price) | currency
                                      }}</strong
                                    >)
                                  </li>
                                </ul>
                              </div>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </td>
                      <td class="unpaid_item_total text-right">
                        {{ item.finalPrice | currency }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </v-container>
          <!-- end list items -->
        </v-card-text>

        <!--        <v-card-actions class="">
                  <v-btn block depressed color="primary">
                    new order
                  </v-btn>
                </v-card-actions>-->
      </v-card>

      <v-card-actions
        v-if="this.hasUnpaidOrder && this.unpaidTotal > 0"
        class="elevation-12 px-5 pt-4 c-rounded-top fixed-bottom white"
      >
        <v-row>
          <v-col v-if="this.loading_unpaid" cols="12">
            <v-progress-linear
              v-if="this.loading_unpaid"
              indeterminate
              color="primary"
            />
          </v-col>
          <v-col cols="6">
            <strong id="unpaid_items_footer_total_title"
              >{{ this.$t("generic.lang_total") }}:</strong
            >
          </v-col>
          <v-col class="text-right" cols="6">
            <strong id="unpaid_items_footer_total">{{
              this.unpaidTotal | currency
            }}</strong>
          </v-col>
          <v-col cols="12" class="pt-0 px-2">
            <v-btn
              block
              rounded
              @click="paymentDialog = true"
              depressed
              class="white--text"
              large
              color="black"
            >
              {{ this.$t("generic.lang_pay_bill") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-navigation-drawer>

    <!-- start: payment type dialog -->
    <v-dialog v-model="paymentDialog">
      <v-card class="bg">
        <!--start title icon-->
        <v-card-title class="text-center">
          {{ this.$t("generic.lang_choosePayment") }}
        </v-card-title>
        <!--end title icon-->
        <v-card-text class="pb-3 pt-0 pt-2">
          <v-btn
            depressed
            @click="payWith(1)"
            block
            :disabled="this.asking_payment"
            :loading="this.asking_payment"
            color="primary"
          >
            <v-icon>
              mdi-cash-multiple
            </v-icon>
            {{ this.$t("generic.lang_pay_cash") }}
          </v-btn>

          <v-btn
            outlined
            class="mt-2"
            @click="payWith(3)"
            block
            :disabled="this.asking_payment"
            :loading="this.asking_payment"
            color="primary"
          >
            <v-icon>
              mdi-credit-card
            </v-icon>
            {{ this.$t("generic.lang_pay_ec") }}
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- end: payment type dialog -->

    <!--End NavBar-->
  </div>
</template>

<script>
/**
 * import plugins
 */
import { createNamespacedHelpers, mapState } from "vuex";

/**
 * import components
 */
import VueQRCodeComponent from "vue-qrcode-component";
//import LanguageSwitcherWidget from "@/components/Widget/LanguageSwitcherWidget.vue";

export default {
  name: "Header",
  components: {
    /*LanguageSwitcherWidget,*/
    "qr-code": VueQRCodeComponent
  },
  data() {
    return {
      menuIconColor: "white",
      drawer: null,
      unpaidTotal: 0,
      loading_unpaid: false,
      calling_waiter: false,
      paymentDialog: false,
      asking_payment: false,
      navItems: [
        {
          title: this.$t("generic.lang_navHome"),
          icon: "mdi-view-dashboard",
          link: "#"
        },
        {
          title: this.$t("generic.lang_navAbout"),
          icon: "mdi-forum",
          link: "#"
        }
      ]
    };
  },
  computed: {
    ...mapState(["Order"]),
    ...createNamespacedHelpers("Table").mapGetters({
      party: "party",
      table_uuid: "uuid",
      table_name: "table"
    }),
    ...createNamespacedHelpers("Order").mapGetters([
      "total",
      "order",
      "ordered",
      "unpaidOrder",
      "unpaidItems",
      "orders",
      "itemTotalPrice"
    ]),
    ...createNamespacedHelpers("Config").mapGetters([
      "Images",
      "hide_waiter_button",
      "can_order_as_group"
    ]),
    hasUnpaidOrder() {
      return (
        this.unpaidOrder !== null &&
        this.unpaidOrder !== undefined &&
        this.unpaidOrder !== "" &&
        this.unpaidOrder.length >= 16
      );
    },
    hasOrder() {
      if (Array.isArray(this.orders) && this.orders.length > 0) return true;
      if (this.order && this.order.id && this.order.id.length >= 16)
        return true;
      return (
        this.unpaidOrder !== null &&
        this.unpaidOrder !== undefined &&
        this.unpaidOrder !== "" &&
        this.unpaidOrder.length >= 16
      );
    }
  },
  watch: {
    drawer(val) {
      if (val && this.hasUnpaidOrder) {
        this.loading_unpaid = true;
        this.$axios
          .get(
            window.ENDPOINTS.SELF_SERVICE.ORDERS.TEMP_ORDERS.MERGED_ORDERS +
              this.unpaidOrder
          )
          .then(res => {
            if (res.status === 200 && typeof res.data === "object") {
              const { total } = res.data.data;
              this.unpaidTotal = total;
            }
          })
          .catch(() => {})
          .finally(() => {
            this.loading_unpaid = false;
          });
      } else if (!val) {
        this.unpaidTotal = 0;
      }

      this.$forceUpdate();
    }
  },
  methods: {
    payWith(type) {
      this.asking_payment = true;
      this.$axios
        .post(window.ENDPOINTS.SELF_SERVICE.NOTIFY, {
          table: {
            uuid: this.table_uuid,
            name: this.table_name,
            party: this.party
          },
          type: 2,
          message: "",
          paymentType: type
        })
        .then(res => {
          if (res.data.success === true) {
            this.paymentDialog = false;
            this.$swal({
              title: this.$t("generic.lang_success"),
              text: this.$t("generic.lang_waiter_will_join_you"),
              icon: "success"
            });
          }
        })
        .catch(() => {
          this.$swal({
            title: this.$t("errors.lang_try_again"),
            text: this.$t("generic.lang_cant_call_waiter"),
            icon: "error"
          });
        })
        .finally(() => {
          this.asking_payment = false;
        });
    },
    callWaiter() {
      this.calling_waiter = true;
      this.$axios
        .post(window.ENDPOINTS.SELF_SERVICE.NOTIFY, {
          table: {
            uuid: this.table_uuid,
            name: this.table_name,
            party: this.party
          },
          type: 1,
          message: "",
          paymentType: null
        })
        .then(res => {
          if (res.data.success === true)
            this.$swal({
              title: this.$t("generic.lang_success"),
              text: this.$t("generic.lang_waiter_will_join_you"),
              icon: "success"
            });
        })
        .catch(() => {
          this.$swal({
            title: this.$t("errors.lang_try_again"),
            text: this.$t("generic.lang_cant_call_waiter"),
            icon: "error"
          });
        })
        .finally(() => {
          this.calling_waiter = false;
        });
    }
  }
};
</script>

<style scoped>
.c-rounded-top {
  border-top-right-radius: 25pt;
  border-top-left-radius: 25pt;
}

#qr-container {
  width: 150px;
  height: 150px;
}

#qr-sheet {
  position: relative;
}

#order-basket {
  position: fixed;
  z-index: 90;
  bottom: 10%;
  left: 5px;
}

#party_name {
  position: relative;
  text-align: center;
  font-size: 14pt;
}

#party_name::after {
  position: absolute;
  content: " ";
  width: 50px;
  height: 1px;
  opacity: 0.5;
  background-color: black;
  bottom: -7px;
  left: 0%;
  right: 0%;
  margin: auto;
}

#unpaid_items_title,
.order_x_items_title {
  font-size: 14pt;
  font-weight: bold;
  color: black;
}

.unpaid_items_name {
  font-weight: 600;
  font-size: 13pt;
}

.unpaid_item_total {
  font-weight: 600;
  font-size: 13pt !important;
}

.unpaid_items_quantity,
.unpaid_items_price {
  font-weight: 400;
  color: black;
  font-size: 13pt;
}

.table_header_titles {
  font-size: 14pt !important;
  font-weight: 500 !important;
}

#unpaid_items_footer_total_title,
#unpaid_items_footer_total {
  font-size: 15pt;
}
</style>

<style>
div.unpaid_item.v-list-item,
div.unpaid_item div.v-list-item__content {
  padding: 0 !important;
}

div.items_simple_table td {
  border: none !important;
  padding: 0 !important;
}

div.items_simple_table th {
  padding: 0 !important;
  border-bottom-color: black !important;
}

.animated {
  background-repeat: no-repeat;
  -webkit-animation-duration: 10s;
  animation-duration: 10s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes swing {
  20%,
  40%,
  60%,
  80%,
  100% {
    -webkit-transform-origin: top center;
  }
  20% {
    -webkit-transform: rotate(15deg);
  }
  40% {
    -webkit-transform: rotate(-10deg);
  }
  60% {
    -webkit-transform: rotate(5deg);
  }
  80% {
    -webkit-transform: rotate(-5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
  }
}

@keyframes swing {
  20% {
    transform: rotate(20deg);
  }
  40% {
    transform: rotate(-15deg);
  }
  60% {
    transform: rotate(10deg);
  }
  80% {
    transform: rotate(-10deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.swing {
  -webkit-transform-origin: top center;
  transform-origin: top center;
  -webkit-animation-name: swing;
  animation-name: swing;
  animation-delay: 0;
  animation-iteration-count: infinite;
  animation-direction: forward;
}
</style>
