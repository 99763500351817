<template>
  <v-dialog
    :overlay-opacity="0.8"
    transition
    scrollable
    max-width="100%"
    v-model="visible"
  >
    <v-card
      elevation="10"
      tile
      class="rounded-0 bg  fixed-bottom position-fixed"
      min-height="100%"
      max-height="90%"
    >
      <!-- header background -->
      <div
        id="header_image"
        v-bind:style="{
          backgroundImage: `url('${item.image || this.Images.product_image}'`
        }"
      >
        <!-- back button -->
        <v-btn
          @click="visible = false"
          min-width="40"
          max-width="40"
          min-height="40"
          max-height="40"
          id="back_btn"
          class="pa-0 px-0 mt-3 ml-3"
          color="#ffffffab"
          small
          elevation="0"
        >
          <v-icon large color="primary" class="pa-0">
            mdi-chevron-left
          </v-icon>
        </v-btn>
      </div>
      <!--start title icon-->
      <v-card-title
        v-touch="{
          down: () => swipeDown()
        }"
        style="margin-top: -10px"
        class="text-left bg top-rounded mb-0 white"
      >
        <i id="top-divider" class="mx-auto mt-2"></i>
        <v-list-item class="mb-0 pb-0">
          <v-list-item-content>
            <v-list-item-title class="text-h5">
              {{ item.name }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-card-title>
      <!--end title icon-->
      <v-card-text min-height="100%" class="">
        <!--description-->
        <p
          v-html="this.default_product_description"
          class="pt-3"
          v-if="this.show_default_product_info"
        ></p>
        <div v-if="this.show_product_description" class="pt-3">
          <div v-html="this.item.description"></div>
        </div>

        <!-- start allergens -->
        <v-expansion-panels
          v-if="
            this.show_allergens &&
              Array.isArray(this.item.allergens) &&
              this.item.allergens.length > 0
          "
          class="elevation-0 bg pa-0 ma-0 pt-2"
        >
          <v-expansion-panel class="elevation-0 bg pa-0 ma-0">
            <v-expansion-panel-header class="elevation-0 pa-0 ma-0">
              <h5 class="text-muted">{{ $t("generic.lang_Allergens") }}</h5>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="bg">
              <v-list
                v-if="
                  Array.isArray(this.item.allergens) &&
                    this.item.allergens.length > 0
                "
                class="bg"
              >
                <v-list-item
                  class="pt-0 pb-0"
                  :key="allergen.id"
                  v-for="allergen in this.item.allergens"
                >
                  <v-list-item-icon>
                    <v-icon color="accent" small>mdi-square</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ allergen.name }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <p class="success--text" v-else>
                {{ $t("generic.lang_itemHasNoAllergens") }}
              </p>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <!-- end allergens -->

        <!-- start additives -->
        <v-expansion-panels
          v-if="
            Array.isArray(this.item.additives) && this.item.additives.length > 0
          "
          class="elevation-0 bg pa-0 ma-0 pt-2"
        >
          <v-expansion-panel class="elevation-0 bg pa-0 ma-0">
            <v-expansion-panel-header class="elevation-0 pa-0 ma-0">
              <h5 class="text-muted">{{ $t("generic.lang_Additives") }}</h5>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="bg">
              <v-list
                v-if="
                  Array.isArray(this.item.additives) &&
                    this.item.additives.length > 0
                "
                class="bg"
              >
                <v-list-item
                  class="pt-0 pb-0"
                  :key="additive.id"
                  v-for="additive in this.item.additives"
                >
                  <v-list-item-icon>
                    <v-icon color="accent" small>mdi-square</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ additive.name }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <!-- end additives -->

        <!-- start: nutrition -->
        <v-expansion-panels
          v-if="typeof item.nutritionTable === 'object'"
          class="elevation-0 bg pa-0 ma-0 pt-2"
        >
          <v-expansion-panel class="elevation-0 bg pa-0 ma-0">
            <v-expansion-panel-header class="elevation-0 pa-0 ma-0">
              <h5 class="text-muted">
                {{ $t("generic.lang_nutritionTable") }}
              </h5>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="bg px-0 nutrition-table">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>
                        <strong>Nutrition Unit</strong>
                      </th>
                      <th class="text-right">
                        <strong>
                          {{ item.nutritionTable.unit }}
                        </strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody class="border">
                    <tr>
                      <td>{{ $t("generic.lang_nutritionKJCal") }}</td>
                      <td class="text-right">
                        {{ item.nutritionTable.calories_kj }}kj
                      </td>
                    </tr>

                    <tr>
                      <td>{{ $t("generic.lang_nutritionKcalCal") }}</td>
                      <td class="text-right">
                        {{ item.nutritionTable.calories_kcal }}kcal
                      </td>
                    </tr>

                    <tr>
                      <td>{{ $t("generic.lang_nutritionFatInG") }}</td>
                      <td class="text-right">{{ item.nutritionTable.fat }}g</td>
                    </tr>

                    <tr>
                      <td>{{ $t("generic.lang_nutritionSaturatedFatInG") }}</td>
                      <td class="text-right">
                        {{ item.nutritionTable.saturated_fat }}g
                      </td>
                    </tr>

                    <tr>
                      <td>
                        {{ $t("generic.lang_nutritionCarbohydratesInG") }}
                      </td>
                      <td class="text-right">
                        {{ item.nutritionTable.carbohydrate }}g
                      </td>
                    </tr>

                    <tr>
                      <td>{{ $t("generic.lang_nutritionSugarInG") }}</td>
                      <td class="text-right">
                        {{ item.nutritionTable.sugars }}g
                      </td>
                    </tr>

                    <tr>
                      <td>{{ $t("generic.lang_nutritionProteinInG") }}</td>
                      <td class="text-right">
                        {{ item.nutritionTable.protein }}g
                      </td>
                    </tr>

                    <tr>
                      <td>{{ $t("generic.lang_nutritionSaltInG") }}</td>
                      <td class="text-right">
                        {{ item.nutritionTable.salt }}g
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <!-- end: nutrition -->
      </v-card-text>

      <!--      <v-card-actions>
              <v-btn
                  elevation="0"
                  large
                  :disabled="checkItem(item.id)"
                  color="primary"
                  block
                  class="mx-auto shadow-lg"
              >
                <v-icon large v-if="checkItem(item.id)">
                  mdi-check
                </v-icon>
                <span v-else>
                  {{ this.item.price | currency }}
                </span>
              </v-btn>
            </v-card-actions>-->
    </v-card>
  </v-dialog>
</template>

<script>
/**
 * i;port plugins
 */
import { createNamespacedHelpers } from "vuex";

export default {
  name: "ProductInfoDialog",
  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...createNamespacedHelpers("Order").mapGetters(["checkItem"]),
    ...createNamespacedHelpers("Config").mapGetters([
      "Images",
      "show_allergens",
      "show_default_product_info",
      "show_product_description",
      "default_product_description"
    ]),
    visible: {
      get() {
        return this.dialog;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  },
  methods: {
    /*close dialog on swipe down*/
    swipeDown() {
      this.visible = false;
    }
  }
};
</script>

<style scoped>
.top-rounded {
  border-top-left-radius: 50px !important;
  border-top-right-radius: 50px !important;
}

#top-divider {
  width: 50px;
  height: 4px;
  background-color: lightgrey;
  border-radius: 30px;
}

.v-expansion-panel::before {
  box-shadow: none !important;
}

.v-input--selection-controls .v-input__slot > .v-label,
.v-input--selection-controls .v-radio > .v-label {
  top: 5px !important;
}

#header_image {
  min-height: 200px !important;
  background-position: center;
}

#back_btn {
  width: 30px !important;
  height: 30px !important;
  padding: 0 !important;
  border-radius: 13px;
}
</style>

<style>
.nutrition-table .v-expansion-panel-content__wrap {
  padding: 0 !important;
}
</style>
