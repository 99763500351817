<template>
  <v-container
    fluid
    id="container"
    class="mx-auto overflow-hidden pa-0 white"
    color="white"
    tile
    elevation="0"
  >
    <div class="text-end primary d-flex">
      <v-btn
        v-if="this.isWaitingApprove"
        @click="commitOrders"
        :loading="this.approving"
        :disabled="this.approving"
        class="my-3 mx-2 rounded-tl-0"
        rounded
        color="white"
      >
        {{ $t("generic.lang_is_last_order") }}
      </v-btn>
      <v-spacer></v-spacer>
      <div>
        <h6 class="py-2 px-5 my-4 font-weight-bold lighten-3">
          {{ $t("generic.lang_tableNo") }} {{ this.table }}
        </h6>
      </div>
    </div>
    <!--Start NavBar-->
    <Header />
    <!--End NavBar-->
    <v-row class="primary pt-3 pb-6">
      <v-col cols="12">
        <v-row no-gutters align="center" justify="center">
          <v-col cols="11" xs="11" sm="11" class="pl-0 white--text">
            <h5 class="font-weight-bold text-h5">Hey, {{ this.party }}!</h5>
            <h5 class="" v-html="this.Messages.menu_page"></h5>
          </v-col>
          <!--Start Notification Badge-->
          <!-- <v-col cols="3" xs="3" sm="3" class="pl-9">
            <v-badge
              :content="notification"
              :value="notification"
              color="white"
              overlap
              class="mx-auto"
            >
              <v-icon color="white" size="30">
                mdi-bell-outline
              </v-icon>
            </v-badge>
          </v-col>-->
          <!--End Notification Badge-->
        </v-row>
      </v-col>
      <!--Start Search input-->
      <!--<v-col cols="12" class="pl-7 pr-7">
        <v-text-field
          :placeholder="$t('generic.lang_searchDishes')"
          dense
          outlined
          color="white"
          v-model="search"
          autocomlete="false"
          hide-details
        >
          solo-inverted
          <template v-slot:append>
            <v-btn
              fab
              dark
              elevation="0"
              x-small
              color="transparent"
              @click="isShowingFilter = true"
              id="filter_icon"
            >
              <v-icon dark color="white" size="24px">
                mdi-tune
              </v-icon>
            </v-btn>
          </template>
        </v-text-field>
      </v-col>-->
      <!--End Search input-->
      <!--Start Filter info-->
      <!--      <v-col cols="3" xs="3" sm="3" class="pr-6 text-right">
              <v-btn
                  fab
                  dark
                  elevation="0"
                  x-small
                  color="tertiary lighten-3 pa-5 mb-1"
                  @click="isShowingFilter = true"
              >
                <v-icon dark color="primary" size="24px">
                  mdi-tune
                </v-icon>
              </v-btn>
            </v-col>-->
      <!--  Start filter component  -->
      <filter-component
        v-if="false"
        v-model="isShowingFilter"
        v-bind:dialog="isShowingFilter"
      />
      <!--  End filter component  -->
      <!--End Filter info-->
    </v-row>
    <!--Start Products Menu-->
    <menu-items v-bind:search="this.search" />
    <!--End Products Menu-->
  </v-container>
</template>
<script>
/*import: components*/
import Header from "@/components/Widget/Header.vue";
import menuItems from "@/components/Menu/menuItems.vue";
/*import: plugins*/
import { createNamespacedHelpers } from "vuex";
import FilterComponent from "@/components/Menu/FilterComponent";
import ENDPOINTS from "@/plugins/axios/endpoints";

export default {
  components: { FilterComponent, Header, menuItems },
  computed: {
    ...createNamespacedHelpers("Order").mapGetters(["isWaitingApprove"]),
    ...createNamespacedHelpers("Table").mapGetters(["table", "party"]),
    ...createNamespacedHelpers("Config").mapGetters(["Messages"]),
    /*sync search input with vuex*/
    search: {
      get() {
        return this.$store.getters["Filter/search"];
      },
      set(val) {
        this.$store.commit("Filter/setSearch", val);
      }
    }
  },
  data() {
    return {
      notification: 0,
      isShowingFilter: false,
      tab: null,
      approving: false
    };
  },
  methods: {
    ...createNamespacedHelpers("Order").mapMutations(["waitApprove"]),
    commitOrders() {
      this.approving = true;
      this.$axios
        .post(ENDPOINTS.SELF_SERVICE.ORDERS.TEMP_ORDERS.APPROVE, {
          tableName: this.table
        })
        .then(res => {
          //this.$router.push("/menu");
          //if ordering as group is enabled we have to indicate that this order is marked as last order in the table
          if (res.data.success) {
            this.waitApprove(false);
            this.$forceUpdate();
          } else {
            this.$swal({
              title: this.$t("errors.lang_cant_create_order"),
              text: this.$t("errors.lang_try_again"),
              icon: "error"
            });
          }
        })
        .catch(() => {
          this.$swal({
            title: this.$t("errors.lang_cant_create_order"),
            text: this.$t("errors.lang_try_again"),
            icon: "error"
          });
        })
        .finally(() => (this.approving = false));
    }
  }
};
</script>
<style scoped>
#container {
  height: 100% !important;
}

#filter_icon {
  position: absolute;
  right: 4px;
  top: 4px;
}
</style>
