<template>
  <div v-scroll="scrolled">
    <!--Start shopping cart-->
    <div class="text-center">
      <v-dialog
        v-model="visible"
        width="100%"
        fullscreen
        scrollable
        transition="dialog-bottom-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <!--Start Menu Page Basket button-->
          <v-btn
            id="showBtn"
            width="100%"
            height="65px"
            :color="can_change_color ? 'primary' : 'accent'"
            class="text-capitalize mb-5"
            dark
            v-bind="attrs"
            v-on="on"
            style="border-top-right-radius: 40px; border-top-left-radius: 40px; position: fixed; bottom: 0%; right: 0%; left: 0%; z-index: 4"
          >
            <!--todo:HI please remove style attr and use class or id to apply this style -->
            <div class="d-flex justify-center">
              <div>
                <v-badge
                  :content="NProductSelect"
                  :value="NProductSelect"
                  color="primary"
                  overlap
                  bottom
                >
                  <v-icon class="mt-0" size="32px" color="white" dark>
                    mdi-purse
                  </v-icon>
                </v-badge>
              </div>

              <div>
                <h5
                  class="font-weight-regular font-size-sm white--text pl-4 pt-1"
                >
                  {{ $t("generic.lang_shoppingCart") }} ({{ total | currency }})
                </h5>
              </div>
            </div>
          </v-btn>
          <!--End Menu Page Basket button-->
        </template>

        <!--Start Shopping Cart Content-->
        <v-card color="bg">
          <v-card-title class="pa-0 border-bottom pb-0">
            <v-toolbar elevation="0" class="py-3 px-2 transparent">
              <v-toolbar-items class="d-flex">
                <div>
                  <v-toolbar-title
                    class="font-weight-medium font-size-sm pr-2 primary--text text-h6"
                  >
                    {{ $t("generic.lang_shoppingCart") }}
                  </v-toolbar-title>
                </div>

                <div>
                  <v-badge
                    :content="NProductSelect"
                    :value="NProductSelect"
                    color="accent"
                    overlap
                    bottom
                    bordered
                  >
                    <v-icon color="primary" size="27px">
                      mdi-purse-outline
                    </v-icon>
                  </v-badge>
                </div>
              </v-toolbar-items>

              <v-spacer></v-spacer>

              <v-btn
                class="pb-6"
                x-large
                color="primary"
                icon
                dark
                @click="visible = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
          </v-card-title>

          <v-card-text class="mx-0 px-2 pt-5 ">
            <div class="mx-1">
              <h5 class="font-weight-bolder pb-0">
                {{ $t("generic.lang_yourSelection") }}
              </h5>
              <div class="d-flex align-middle align-center">
                <v-icon>
                  mdi-information-outline
                </v-icon>
                <span class="ml-2">
                  {{ this.$t("generic.lang_swipe_right_to_remove") }}
                </span>
              </div>
            </div>

            <!--Start Shopping Cart Orders-->
            <div class="mx-1">
              <shopping-cart-orders-card />
            </div>
            <!--End Shopping Cart Orders-->

            <!--start order note-->
            <!--            <div class="mx-1">
                          <v-textarea
                            v-model="order_note"
                            height="80"
                            dense
                            outlined
                            prepend-inner-icon="mdi-comment"
                            :label="$t('generic.lang_typeOrderNote')"
                          />
                        </div>-->
            <!--end order note-->

            <div class="px-6 mt-3 pb-2 mx-auto text-center">
              <div class="d-flex">
                <h5 class="font-weight-bold primary--text">
                  {{ $t("generic.lang_total") }}
                </h5>
                <v-spacer></v-spacer>
                <h5 class="font-weight-bold primary--text">
                  {{ this.total | currency }}
                </h5>
              </div>
              <!-- commented for now
              <p class="mt-4 small grey--text">
                {{ $t("generic.lang_takeAwayOrderText") }}
              </p>
              -->
            </div>

            <!--order note / message-->
            <!--            <div class="px-5 text-center mt-3">
                          <strong class="text-center ">
                            Please wait until all guests at your table have selected. The order is then bundled and sent to the
                            kitchen for everyone.
                          </strong>
                        </div>-->
            <v-sheet height="200px" color="transparent" />
          </v-card-text>
          <!--Start dialog Basket button-->
          <v-card-actions>
            <v-btn
              @click="redirectToCheckout()"
              width="100%"
              height="65px"
              color="accent"
              dark
              class="text-capitalize"
              style="border-top-right-radius: 40px; border-top-left-radius: 40px; position: fixed; bottom: 0%; right: 0%; left: 0%;"
            >
              <div class="d-flex justify-center">
                <h4 class="font-weight-regular white--text pt-1 pr-2">
                  {{ $t("generic.lang_order_for_my_table") }}
                </h4>

                <v-icon size="26px" color="white" dark>
                  mdi-arrow-right
                </v-icon>
              </div>
            </v-btn>
          </v-card-actions>
          <!--End Dialog Basket button-->
        </v-card>
        <!--End Shopping Cart Content-->
      </v-dialog>
    </div>
    <!--End shopping cart-->
  </div>
</template>

<script>
/*import components*/
import shoppingCartOrdersCard from "@/components/Card/shoppingCartOrdersCard";

/*import plugins*/
import { createNamespacedHelpers } from "vuex";

export default {
  components: { shoppingCartOrdersCard },

  methods: {
    //Redirect To Checkout Page
    redirectToCheckout() {
      this.$router.push({ name: "Checkout" });
    },
    scrolled() {
      this.isFixedBottom = document.getElementById("showBtn").offsetTop;
      /*          (window.pageYOffset || document.documentElement.scrollTop);*/
    }
  },

  props: {
    orders: []
  },
  watch: {
    total(val) {
      if (val) {
        this.can_change_color = true;
        const self = this;
        let timer = setTimeout(() => {
          self.can_change_color = false;
          clearTimeout(timer);
        }, 250);
      }
    }
  },
  computed: {
    ...createNamespacedHelpers("Order").mapGetters([
      "isCardEmpty",
      "card",
      "total",
      "note"
    ]),
    fromBottom() {
      return /*window.pageYOffset || */ document.documentElement.scrollTop;
    },
    /*get number of selected items*/
    NProductSelect() {
      return this.card.length;
    },

    /*order note accessors*/
    order_note: {
      get() {
        return this.note;
      },
      set(val) {
        this.$store.commit("Order/setOrderNote", val);
      }
    }
  },
  data() {
    return {
      //to change color when we add  a new item
      can_change_color: false,
      visible: false,
      isFixedBottom: true
    };
  }
};
</script>
