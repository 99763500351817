<template>
  <!--***-->
  <div class="rounded-lg mx-4 mt-2">
    <v-card
      id="itemCard"
      class="align-center text-start px-0"
      elevation="0"
      v-bind:style="this.productCardStyle"
      color="product_card"
    >
      <v-card-text class="pa-0 px-0 ma-0 mx-0">
        <!--***-->
        <v-list-item three-line class="mx-0">
          <v-list-item-content>
            <v-list-item-title
              @click="setCurrent"
              class="font-weight-bold mb-0 text-lg-h6 product_name--text "
            >
              {{ item.name }}
            </v-list-item-title>

            <v-list-item-subtitle
              @click="setCurrent"
              class="product_description--text item_description"
            >
              <span
                class="mt-3"
                style="font-size: 10pt !important;"
                v-html="item.description"
              ></span>
            </v-list-item-subtitle>
            <div
              class="d-flex align-middle"
              v-if="parseFloat(this.itemDiscount) > 0"
            >
              <span class="primary--text" id="discounted">{{
                item.finalizedPrice | currency
              }}</span>
              <span class="ml-2" id="oldPrice">{{
                item.taxedPrice | currency
              }}</span>
            </div>
            <h6 v-else class="py-2 primary--text">
              {{ item.taxedPrice | currency }}
            </h6>

            <p
              v-if="!this.hideProductDetails"
              @click="details"
              class="accent--text small"
            >
              {{ $t("generic.lang_productInfo") }}
            </p>
          </v-list-item-content>

          <v-list-item-avatar tile size="80">
            <img
              @click="showImage(item.image || Images.product_image)"
              v-if="item.image || Images.product_image"
              height="120"
              :src="item.image || Images.product_image"
              :alt="item.name"
            />
          </v-list-item-avatar>

          <v-list-item-action @click="setCurrent" class="">
            <v-btn
              v-if="!isCurrent(item.id)"
              min-width="30"
              min-height="30"
              class="rounded-md position-absolute item_btn"
              color="primary"
              dark
              elevation="0"
              absolute
              @click="open"
            >
              <v-icon small>mdi-plus</v-icon>
            </v-btn>

            <v-btn
              v-else
              min-width="30"
              min-height="30"
              class="rounded-md position-absolute  item_btn"
              color="error"
              dark
              elevation="0"
              absolute
              @click="close"
            >
              <v-icon small>mdi-close</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <!--***-->
      </v-card-text>
      <v-card-actions
        class="border-top pb-0 pt-6 tertiary lighten-3"
        v-if="this.isCurrent(this.item.id)"
      >
        <v-row no-gutters class="pb-0 pr-3 pl-3">
          <!-- start sizes -->
          <v-col
            v-if="Array.isArray(item.sizes) && item.sizes.length > 0"
            cols="12"
            class="pt-3 pb-3"
          >
            <strong>
              {{ $t("generic.lang_meal_sizes") }}
            </strong>
            <v-radio-group v-model="size">
              <v-radio v-for="size in item.sizes" :key="size.id" :value="size">
                <template slot="label">
                  <strong>{{ size.sizeName }}</strong>
                  <div
                    class="d-flex align-middle"
                    v-if="parseFloat(itemDiscount) > 0"
                  >
                    <span class="primary--text ml-2" id="discounted-size">{{
                      size.finalizedPrice | currency
                    }}</span>
                    <span class="ml-2" id="oldPrice-size">{{
                      size.sellPrice | currency
                    }}</span>
                  </div>
                  <h6 v-else class="py-2 primary--text">
                    {{ size.sellPrice | currency }}
                  </h6>
                </template>
              </v-radio>
            </v-radio-group>

            <!--            <v-chip-group :value="this.mealSize" column v-model="size" active-class="primary">
                          <v-chip
                              filter
                              v-for="size in item.sizes"
                              :key="size.id"
                              :value="size"

                          >
                            <strong>{{ size.sizeName }}</strong
                            >&nbsp;<span> {{ size.sellPrice | currency }}</span>
                          </v-chip>
                        </v-chip-group>-->
          </v-col>
          <!-- end sizes -->

          <!-- start extras -->
          <v-col
            v-if="
              !Array.isArray(item.sizes) ||
                (Array.isArray(item.sizes) && item.sizes.length === 0) ||
                (Array.isArray(item.sizes) &&
                  item.sizes.length > 0 &&
                  this.currentMealSize !== null)
            "
            cols="12"
            class="pt-3 pb-3"
          >
            <strong>
              {{ $t("generic.lang_extras") }}
            </strong>
            <v-expansion-panels
              accordion
              style="z-index: 1 !important;"
              class="elevation-0 pa-0 ma-0 pt-2"
            >
              <v-expansion-panel
                v-for="tab in item.extras"
                class="elevation-0 pa-0 ma-0"
                :key="tab.id"
                style="z-index: 1 !important;"
                v-show="canShowExtrasTab(tab)"
                @change="extraTabChanged(tab)"
              >
                <v-expansion-panel-header class="elevation-0 pa-0 pl-2 ma-0">
                  <h5 class="text-muted">
                    {{ tab.tabName }}
                  </h5>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-list class="px-0 mx-0">
                    <v-list-item
                      class="px-0 mx-0 pt-1 pb-1"
                      :key="ext.id + Math.random()"
                      v-show="/*includedInPagination(index)*/ true"
                      v-for="ext in showAllExtras
                        ? extrasFilter_2(tab)
                        : extrasFilter_2(tab).slice(0, 3)"
                    >
                      <v-list-item-content class="px-0 mx-0 pt-0 pb-0">
                        <v-list-item-title>
                          {{ ext.name }}
                          <span
                            v-if="parseFloat(ext.finalizedPrice) > 0"
                            class="primary--text"
                            >{{ ext.finalizedPrice | currency }}</span
                          >
                        </v-list-item-title>
                        <div class="mx-auto">
                          <v-btn
                            width="28px"
                            height="28px"
                            class="rounded-circle pa-0 px-0"
                            color="accent"
                            icon
                            outlined
                            rounded
                            x-small
                            :disabled="
                              !isExtrasSelected(ext) ||
                                (isExtrasSelected(ext) &&
                                  totalQuantityOfSelectedExtrasInTab(tab) <= 0)
                            "
                            @click="pushExtras(false, ext, tab)"
                          >
                            <v-icon class="pa-0 px-0" small>mdi-minus</v-icon>
                          </v-btn>
                          <span class="mr-2 ml-2">{{
                            isNaN(
                              parseInt(
                                ExtrasQuantity({
                                  id: ext.id,
                                  tab_id: tab.id
                                })
                              )
                            )
                              ? 0
                              : ExtrasQuantity({ id: ext.id, tab_id: tab.id })
                          }}</span>

                          <v-btn
                            width="30px"
                            height="30px"
                            class="rounded-circle pa-0"
                            color="accent"
                            icon
                            outlined
                            rounded
                            x-small
                            :disabled="
                              tab.maxChoose !== 0 &&
                                totalQuantityOfSelectedExtrasInTab(tab) >=
                                  tab.maxChoose
                            "
                            @click="pushExtras(true, ext, tab)"
                          >
                            <v-icon small>mdi-plus</v-icon>
                          </v-btn>
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>

                  <div v-if="extrasFilter_2(tab).length > 3">
                    <v-btn
                      block
                      v-if="!showAllExtras"
                      @click="showAllExtras = !showAllExtras"
                      text
                      color="primary"
                    >
                      <v-icon>mdi-chevron-down</v-icon>
                      {{ $t("generic.lang_show_more") }} ({{
                        extrasFilter_2(tab).length
                      }})
                    </v-btn>

                    <v-btn
                      block
                      v-else
                      @click="showAllExtras = !showAllExtras"
                      text
                      color="primary"
                    >
                      <v-icon>mdi-chevron-up</v-icon>
                      {{ $t("generic.lang_show_less") }} (3)
                    </v-btn>
                  </div>

                  <!--extras pagination -->
                  <!--                  <v-pagination
                                        class="elevation-0"
                                        @input="changePaginatorPosition($event)"
                                        v-if="paginator.show"
                                        v-model="paginator.page"
                                        :length="paginator.length"
                                    ></v-pagination>-->
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
          <!-- end extras -->
          <v-col cols="6" class="pb-0 mx-auto text-left">
            <v-text-field
              height="40px"
              v-model="quantity"
              readonly
              dense
              class="text-center centered-input"
              outlined
            >
              <template v-slot:prepend-inner>
                <v-icon @click="minus">mdi-minus</v-icon>
              </template>

              <template v-slot:append>
                <v-icon @click="plus">mdi-plus</v-icon>
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="6" class="pb-0 text-left">
            <v-btn
              @click="addToCard(item)"
              height="40px"
              class="mx-auto"
              block
              elevation="0"
              color="primary"
              :disabled="!this.isValidExtrasLimits"
            >
              {{ this.currentTotal | currency }} &nbsp;<strong
                v-if="parseFloat(this.itemDiscount) > 0"
              >
                ({{ this.itemDiscount + "%" }})</strong
              >
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>

    <!-- product image dialog -->
    <v-dialog max-width="600" v-model="show_image_dialog">
      <v-card>
        <v-card-title class="pt-0 pb-0 pr-2">
          <v-spacer />
          <v-icon large @click="show_image_dialog = false" color="error"
            >mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text>
          <v-img :src="this.image" width="100%" />
        </v-card-text>
      </v-card>
    </v-dialog>

    <product-info-dialog
      v-bind:item="this.item"
      v-bind:dialog="this.details_dialog"
      v-model="details_dialog"
    />
  </div>
  <!--***-->
</template>

<script>
const navig = navigator;
/**
 * import plugins
 */
import { createNamespacedHelpers } from "vuex";
import ProductInfoDialog from "@/components/Menu/ProductInfoDialog";

export default {
  components: { ProductInfoDialog },
  props: {
    item: {
      type: Object,
      required: true
    },
    discount: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      size: null,
      state: null, // to control details,
      details_dialog: false,
      show_image_dialog: false,
      image: "",
      paginator: {
        page: 1,
        length: 0,
        totalVisible: 3,
        position: 1
      },
      showAllExtras: false
    };
  },
  watch: {
    size(val) {
      this.mealSize = val === undefined ? null : val;
    },
    paginator: {
      handler() {
        this.$forceUpdate();
      },
      deep: true
    }
  },
  computed: {
    ...createNamespacedHelpers("Order").mapGetters([
      "isCurrent",
      "currentQuantity",
      "currentExtrasTotal",
      "isExtrasSelected",
      "ExtrasQuantity",
      "totalQuantityOfSelectedExtrasInTab",
      "currentMealSize",
      "itemTotalPrice",
      "current",
      "discountedPrice"
    ]),
    ...createNamespacedHelpers("Config").mapGetters([
      "Images",
      "hideProductDetails",
      "productCardStyle"
    ]),
    //check if a garnish tab has extras for current meal size
    hasExtrasForCurrentSize() {
      return garnish => {
        if (!this.isCurrent(this.item.id)) return false;
        if (!Array.isArray(garnish) || !this.currentMealSize) return false;
        let g = garnish.find(gn => gn.mealsizeId === this.currentMealSize.id);
        return !!g;
      };
    },
    sizeExtras() {
      return tab => {
        if (
          Array.isArray(this.item.sizes) &&
          this.item.sizes.length > 0 &&
          this.currentMealSize
        ) {
          return tab.garnish.filter(
            gn => gn.mealsizeId === this.currentMealSize.id
          );
        }
        return [];
      };
    },
    hasSize() {
      return Array.isArray(this.item.sizes) && this.item.sizes.length > 0;
    },
    canShowExtrasTab() {
      return tab => {
        if (!this.isCurrent(this.item.id)) return false;
        if (Array.isArray(this.item.sizes) && this.item.sizes.length > 0) {
          return this.hasExtrasForCurrentSize(tab.garnish);
        }
        return true;
      };
    },
    /**
     * filter extras of given tab
     * @return {(function(*): (*|[]))|*}
     */
    extrasFilter_2() {
      return tab => {
        if (Array.isArray(tab.garnish) && tab.garnish.length > 0) {
          return tab.garnish.filter(g => this.canShowExtra(g));
        }
        return [];
      };
    },
    canShowExtra() {
      return exta => {
        if (
          Array.isArray(this.item.sizes) &&
          this.item.sizes.length &&
          this.currentMealSize !== null
        ) {
          return exta.active && exta.mealsizeId === this.currentMealSize.id;
        }
        return exta.mealsizeId === 0 && exta.active;
      };
    },
    //filter item extras based on meal sizes
    extrasFilter() {
      return exts => {
        /*if (this.isCurrent(this.item.id) && Array.isArray(exts)) {
          if (Array.isArray(this.item.sizes) && this.item.sizes.length > 0) {
            if (this.currentMealSize !== null) {
              let nExt = [...exts];
              return nExt.map(tab => {
                tab.garnish = tab.garnish.filter(
                    ({mealsizeId}) => this.currentMealSize.id === mealsizeId
                );
                return tab;
              });
            }

            return [];
          }
        }*/

        return exts;
      };
    },
    isValidExtrasLimits() {
      if (
        this.hasSize &&
        (this.currentMealSize === null ||
          this.currentMealSize === {} ||
          this.currentMealSize === undefined)
      )
        return false;
      if (Array.isArray(this.item.extras) && this.isCurrent(this.item.id)) {
        return this.checkExtrasLimits([...this.item.extras]) === 1;
      }
      return false;
    },
    currentTotal() {
      return this.current !== null ? this.itemTotalPrice(this.current) : 0; //this.item.price * this.quantity + this.currentExtrasTotal;
    },
    /**
     * item quantity accessors
     * */
    quantity: {
      get() {
        return this.currentQuantity;
      },
      set(val) {
        this.setCurrentQuantity(val);
      }
    },
    /**
     * select meal size accessors
     */
    mealSize: {
      get() {
        return this.currentMealSize;
      },
      set(val) {
        this.setCurrentMealSize(val);
        this.$forceUpdate();
      }
    },
    //show/hide extras in pagination based on it index
    includedInPagination() {
      return index => {
        if (this.paginator.show) {
          return (
            index + 1 >
              (this.paginator.page - 1) * this.paginator.totalVisible &&
            index + 1 <= this.paginator.page * this.paginator.totalVisible
          );
        }

        return true;
      };
    },
    itemDiscount() {
      if (!Number.isNaN(parseFloat(this.item.discount)))
        return parseFloat(this.item.discount).toFixed(2);
      else if (!Number.isNaN(parseFloat(this.discount)))
        return parseFloat(this.discount).toFixed(2);
      else return 0;
    },
    extras_model: {
      get() {
        const self = this;
        return id => self.isExtrasSelected(id);
      }
    }
  },
  methods: {
    changePaginatorPosition(position) {
      this.paginator.position = position;
    },
    extraTabChanged(tab) {
      this.paginator.page = 1;
      this.paginator.show =
        this.canShowExtrasTab(tab) &&
        Array.isArray(tab.garnish) &&
        this.extrasFilter_2(tab).length > this.paginator.totalVisible;

      //get length
      if (this.paginator.show) {
        let l = 0;

        if (this.hasSize)
          l = this.sizeExtras(tab).length / this.paginator.totalVisible;
        else l = this.extrasFilter_2(tab).length / this.paginator.totalVisible;
        if (Number.isInteger(l)) this.paginator.length = l;
        else this.paginator.length = parseInt(l) + 1;
      }

      this.$forceUpdate();
    },
    showImage(image) {
      this.show_image_dialog = true;
      this.image = image;
    },
    checkExtrasLimits(tabs) {
      if (!Array.isArray(tabs) || (Array.isArray(tabs) && tabs.length === 0))
        return 1;
      let flag = 1;

      //get first one
      let tab = { ...tabs[0] };
      if (Array.isArray(tab.garnish) && this.canShowExtrasTab(tab)) {
        if (
          tab.maxChoose > 0 &&
          this.totalQuantityOfSelectedExtrasInTab(tab) > tab.maxChoose
        )
          flag = 0;
        if (
          tab.minChoose > 0 &&
          this.totalQuantityOfSelectedExtrasInTab(tab) < tab.minChoose
        )
          flag = 0;
      }

      tabs.shift();

      return flag * this.checkExtrasLimits(tabs);
    },
    //allow us to check if specific item respect extras limits before selecting it
    isValidLimits(tab) {
      console.log(tab);
    },
    ...createNamespacedHelpers("Order").mapMutations([
      "setCurrentQuantity",
      "pushExtrasToCurrent",
      "removeExtraFromCurrent",
      "setCurrentMealSize"
    ]),
    /*add item to card in Order state*/
    addToCard(item) {
      navigator.vibrate =
        navigator.vibrate ||
        navigator.webkitVibrate ||
        navigator.mozVibrate ||
        navigator.msVibrate;

      if (navigator.vibrate) navig.vibrate(90);

      this.$store.commit("Order/addToCard", {
        item: item,
        quantity: this.quantity
      });

      //change quantity to the current one
      /*this.$store.commit("Order/changeQty", {
        item: { ...this.current },
        quantity: this.quantity
      });*/

      /*{
        item: item,
            quantity: this.quantity,
          size: this.size
      }*/

      //clear current item
      this.$store.commit("Order/selectCurrent", {});
      this.size = null;
      this.quantity = 1;
    },
    /**
     * open details according
     */
    open() {
      this.state = true;
    },

    /**
     * close details according
     */
    close() {
      this.state = true;
    },
    pushExtras(value, ext, tab) {
      ext.tab_id = tab.id;
      if (value) {
        this.pushExtrasToCurrent(ext);
      } else {
        this.removeExtraFromCurrent(ext);
      }
    },
    setCurrent() {
      navigator.vibrate =
        navigator.vibrate ||
        navigator.webkitVibrate ||
        navigator.mozVibrate ||
        navigator.msVibrate;

      this.item.discount = this.discount;
      if (navigator.vibrate) navig.vibrate(90);

      //let's check if this item don't have any extras
      if (
        (Array.isArray(this.item.sizes) &&
          this.item.sizes.length > 0 &&
          Array.isArray(this.item.extras) &&
          this.item.extras.length > 0) ||
        (Array.isArray(this.item.sizes) && this.item.sizes.length > 0) ||
        (Array.isArray(this.item.extras) && this.item.extras.length > 0)
      ) {
        //we show product details (extras ...)
        this.$store.commit("Order/selectCurrent", this.item);
      } else {
        //then we select the item directly
        this.$store.commit("Order/addToCard", { item: this.item, quantity: 1 });
        //clear current item
        this.$store.commit("Order/selectCurrent", {});
        this.size = null;
        this.quantity = 1;
        this.$forceUpdate();
      }
    },
    minus() {
      if (this.quantity > 1) this.quantity = this.quantity - 1;
    },
    plus() {
      this.quantity = this.quantity + 1;
    },
    /**
     * show product details dialog
     */
    details() {
      this.details_dialog = true;
    }
  },
  mounted() {
    if (this.item && this.isCurrent(this.item.id)) {
      this.$store.commit("Order/selectCurrent", {});
      this.size = null;
    }
  }
};
</script>

<style scoped>
.item_btn {
  position: absolute !important;
  top: 0px !important;
  right: -1px;
  max-width: 20px !important;
  max-height: 20px !important;
}

.centered-input {
  width: 100px !important;
}

.centered-input >>> input {
  text-align: center;
}

.v-list-item__title {
  white-space: normal !important;
}

.item_description {
  display: block !important;
}

#oldPrice,
#oldPrice-size {
  text-decoration: line-through;
  font-size: 13px;
  padding-top: 3px;
}

#discounted,
#discounted-size {
  font-size: 15px;
  font-weight: bold;
  padding-top: 3px;
}
</style>
