<template>
  <div class="pt-0">
    <v-sheet
      v-for="(order, index) in card"
      :key="index"
      v-touch="{
        right: () => cancelItem(order)
      }"
      class="rounded-lg border-bottom   transparent my-3 pb-0"
    >
      <div class="product-card-container">
        <v-list-item class="pa-0  transparent">
          <v-list-item-avatar
            class="pa-0 mr-2 transparent rounded-lg"
            tile
            size="50"
          >
            <v-img :src="order.item.image || Images.product_image" />
          </v-list-item-avatar>
          <v-list-item-content
            class="grey mx-0 lighten-2 rounded-lg py-0 px-2 py-1"
          >
            <v-row
              align-content="center"
              justify="center"
              align="center"
              no-gutters
              class="ma-0 pa-0"
            >
              <!--              <v-col cols="2">
                              <v-btn
                                  class="mx-auto"
                                  @click="cancelItem(order)"

                                  small
                                  max-width="30"
                                  min-width="30"
                                  outlined
                                  max-height="30"
                                  min-height="30"
                                  depressed
                                  color="error"
                              >
                                <v-icon small>mdi-trash-can</v-icon>
                              </v-btn>
                            </v-col>-->
              <v-col cols="8">
                <v-list-item-title>
                  <span class="mx-auto" style="z-index: 1;">
                    {{ order.item.name }}
                    <span v-if="order.size">( {{ order.size.sizeName }} )</span>
                  </span>
                </v-list-item-title>
                <v-list-item-subtitle class="mt-2">
                  <div>
                    <strong
                      class="primary--text font-weight-bold"
                      style="z-index: 1;"
                    >
                      {{
                        parseFloat(
                          itemTotalPrice(order) / order.quantity
                        ).toFixed(2) | currency
                      }}
                    </strong>

                    <span class="accent--text ml-3" style="z-index: 1;">
                      {{ itemTotalPrice(order) | currency }}
                    </span>
                  </div>
                </v-list-item-subtitle>
              </v-col>
              <v-col cols="4" class="text-right d-flex">
                <v-spacer />

                <v-btn
                  class="pa-0"
                  max-width="30"
                  min-width="30"
                  max-height="30"
                  min-height="30"
                  color="primary"
                  depressed
                  @click="plus(order)"
                >
                  <v-icon small>mdi-plus</v-icon>
                </v-btn>

                <span class="font-weight-bold my-auto  size--large mx-2">{{
                  order.quantity
                }}</span>

                <v-btn
                  class="pa-0"
                  max-width="30"
                  min-width="30"
                  max-height="30"
                  min-height="30"
                  color="primary"
                  depressed
                  :disabled="order.quantity <= 0"
                  @click="minus(order)"
                >
                  <v-icon class="pa-0 px-0">mdi-minus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>

        <!--      note & extras container-->
        <div class="ml-15 pb-2">
          <!--          extras list-->
          <div
            v-if="Array.isArray(order.extras) && order.extras.length > 0"
            class="pl-0"
          >
            <ul
              style="
    list-style-type: none;
"
              class="pl-0"
            >
              <li
                :key="ext.id + '-' + index"
                v-for="(ext, index) in order.extras"
              >
                <strong class="primary--text">{{ ext.quantity }}</strong
                >x {{ ext.name }} (<strong class="primary--text">{{
                  (ext.quantity * ext.finalizedPrice) | currency
                }}</strong
                >)
              </li>
            </ul>
          </div>

          <!--note-->
          <div @click="openNote(order)" class="d-flex">
            <v-icon small>mdi-comment-edit-outline</v-icon>
            <span class="d-block ml-2 text-sm-caption  text-muted">{{
              (order.note && order.note.substring(0, 80)) ||
                $t("generic.lang_add_note")
            }}</span>
          </div>
        </div>
      </div>

      <!--      <div
              style="height: 80px"
              class="d-flex justify-space-around justify-center"
            >
              <div class=" text-center my-auto w-25">
                <div>
                  <span class="font-weight-regular">{{ order.quantity }}x</span>
                </div>

                <v-btn
                  width="20px"
                  height="20px"
                  class="rounded-circle mr-1 pa-0"
                  color="accent"
                  icon
                  outlined
                  rounded
                  x-small
                  @click="plus(order)"
                >
                  <v-icon x-small>mdi-plus</v-icon>
                </v-btn>

                <v-btn
                  class="rounded-circle pa-0 px-0"
                  color="accent"
                  icon
                  outlined
                  rounded
                  x-small
                  :disabled="order.quantity <= 1"
                  @click="minus(order)"
                >
                  <v-icon class="pa-0 px-0" x-small>mdi-minus</v-icon>
                </v-btn>
              </div>

              <div class="d-flex align-center mx-auto ">
                <div class="mx-auto d-block text-left">
                  <span class="font-weight-bold mx-auto" style="z-index: 1;">
                    {{ order.item.name }}
                    <span v-if="order.size">( {{ order.size.sizeName }} )</span>
                  </span>
                  <span
                    class="d-block text-sm-caption small text-muted"
                    v-if="order.note"
                    >{{ order.note }}</span
                  >
                </div>

                &lt;!&ndash;          <div>
                            <v-avatar size="60">
                              <v-img :src="order.item.image" />
                            </v-avatar>
                          </div>&ndash;&gt;

                <div class="mx-1 tex-right">
                  <strong class="primary&#45;&#45;text" style="z-index: 1;">
                    {{
                      parseFloat(itemTotalPrice(order) / order.quantity).toFixed(2)
                        | currency
                    }}
                  </strong>
                  <br />
                  <strong class="accent&#45;&#45;text" style="z-index: 1;">
                    {{ itemTotalPrice(order) | currency }}
                  </strong>
                </div>
              </div>

              <div class="d-flex align-center mx-auto ">
                <v-btn
                  class="mx-auto"
                  @click="cancelItem(order)"
                  icon
                  small
                  color="black"
                >
                  <v-icon small>mdi-trash-can</v-icon>
                </v-btn>

                <v-btn @click="openNote(order)" icon small color="black">
                  <v-icon small>mdi-comment</v-icon>
                </v-btn>
              </div>
            </div>-->
      <!-- selected extras -->
    </v-sheet>

    <!--start note dialog-->
    <item-note-dialog v-bind:dialog="note_dialog" v-model="note_dialog" />
    <!--end note dialog-->

    <!--delete item dialog-->
    <v-dialog v-if="deleteItemDialog" v-model="deleteItemDialog">
      <v-card class="bg">
        <v-card-title class="text-center">
          {{ this.selectedItem.item && this.selectedItem.item.name }}
        </v-card-title>
        <!--end title icon-->
        <v-card-text class="pb-0 text-center pt-2">
          {{ this.$t("generic.lang_delete_item_confirm") }}
        </v-card-text>

        <v-card-actions class="pt-0 mt-3">
          <v-btn @click="deleteItemDialog = false" text color="primary">
            {{ $t("generic.lang_cancel") }}
          </v-btn>
          <v-spacer />
          <!--confirm btn-->
          <v-btn
            :loading="loading"
            :disabled="loading"
            @click="deleteResolve(true)"
            elevation="0"
            color="error"
          >
            {{ $t("generic.lang_yes") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/*import plugins*/
import { createNamespacedHelpers } from "vuex";
import ItemNoteDialog from "@/components/Card/ItemNoteDialog";

export default {
  components: { ItemNoteDialog },
  data: () => ({
    note_dialog: false,
    deleteResolve: null,
    deleteReject: null,
    deleteItemDialog: false,
    loading: false
  }),
  watch: {
    deleteItemDialog(val) {
      if (!val) {
        if (this.deleteReject) this.deleteReject(false);
      }
    }
  },
  computed: {
    /*reference Order getters*/
    ...createNamespacedHelpers("Order").mapGetters([
      "card",
      "itemTotalPrice",
      "selectedItem"
    ]),
    ...createNamespacedHelpers("Config").mapGetters(["Images"]),
    orders() {
      return this.card;
    }
  },
  methods: {
    openDeleteDialog() {
      return new Promise((resolve, reject) => {
        this.deleteResolve = resolve;
        this.deleteReject = reject;
        this.deleteItemDialog = true;
      });
    },
    /*increment quantity*/
    plus(item) {
      this.$store.commit("Order/addToCard", { item: item, quantity: 1 });
    },
    /*decrement quantity*/
    minus(item) {
      if (parseInt(item.quantity) - 1 > 0) {
        this.$store.commit("Order/changeQty", {
          item,
          quantity: parseInt(item.quantity) - 1
        });
      } else {
        this.cancelItem(item);
      }
    },
    /*remove item*/
    async cancelItem(item) {
      this.$store.commit("Order/selectItem", item);
      await this.openDeleteDialog().then(() => {
        this.$store.commit("Order/removeItem", item);
        this.deleteItemDialog = false;
      });
    },
    /*open note dialog*/
    openNote(item) {
      /*save current item*/
      this.$store.commit("Order/selectItem", item);

      this.note_dialog = true;
    }
  }
};
</script>

<style>
.v-list-item__title,
.v-list-item__subtitle {
  white-space: normal !important;
}

.product-card-container {
  border-bottom: 1px dashed rgba(128, 128, 128, 0.4) !important;
}
</style>
